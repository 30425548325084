<template>
    <div class="flex justify-between align-center" style="height:40px;">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="item.path" v-for="(item, index) in breadcrumbList" :key="index">
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div>
            <span class="font mr-1">你好，{{ fullName }}</span>
            <el-button size="mini" round @click="exit">退出</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            breadcrumbList: [],
            fullName: "",
        };
    },
    mounted() {
        this.breadcrumbList = this.$route.matched;
        this.fullName = sessionStorage.getItem("user_fullName");
    },
    watch: {
        $route(to, from) {
            this.breadcrumbList = to.matched;
        },
    },
    methods: {
        exit: function() {
            sessionStorage.removeItem("user_token");
            sessionStorage.removeItem("user_fullName");
            this.$message({
                message: "账号已安全退出",
                type: "success",
            });
            this.$router.push("/login");
        },
    },
};
</script>
