<template>
    <div id="menu">
        <el-menu :default-active="$route.path" background-color="#324154" text-color="#c1cbd8" active-text-color="#3f9bf4" router>
            <template v-for="item in menuList">
                <template v-if="item.child">
                    <el-submenu :index="item.title" :key="item.title">
                        <template slot="title">
                            <i class="el-icon-menu"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <el-menu-item-group v-if="item.child">
                            <el-menu-item v-for="child in item.child" :index="child.url" :key="child.url">
                                <i class="el-icon-caret-right"></i>
                                <span>{{ child.title }}</span>
                            </el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.url" :key="item.title">
                        <i class="el-icon-menu"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            menuList: [
                {
                    title: "首页",
                    url: "/home/welcome",
                },
                {
                    title: "运营管理",
                    child: [
                        { title: "订单管理 ", url: "/order/list" },
                        { title: "用户管理", url: "/operate/user" },
                        { title: "活动公告", url: "/operate/lunbo" },
                        { title: "运营报表", url: "/operate/report" },
                    ],
                },
                {
                    title: "商品管理",
                    child: [
                        { title: "新增商品", url: "/item/create" },
                        { title: "商品列表", url: "/item/list" },
                    ],
                },
                {
                    title: "分类管理",
                    child: [
                        { title: "新增类别", url: "/artist/create" },
                        { title: "分类列表", url: "/artist/list" },
                    ],
                },
                {
                    title: "系统管理",
                    child: [
                        { title: "新增管理员", url: "/admin/create" },
                        { title: "管理员列表", url: "/admin/list" },
                        { title: "联系方式设置", url: "/operate/contact" },
                    ],
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
