<template>
    <el-container style="height:100%;">
        <el-aside width="200px">
            <Menu />
        </el-aside>
        <el-container>
            <el-header style="height:40px">
                <Header />
            </el-header>
            <el-main>
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Menu from "@/components/Menu";
import Header from "@/components/Header";
export default {
    components: { Menu, Header },
};
</script>
<style>
.el-aside {
    background-color: #354152;
    color: white;
    line-height: 200px;
    overflow-y: hidden;
}
.el-main {
    background-color: white;
}
</style>
